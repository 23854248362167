import React from "react";
import PropTypes from "prop-types";

const KeyInfo = ({keyData, keyDataLabel}) => (
  <div className="Key-info">
    <h3>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g>
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12 22C6.477 22 2 17.523 2 12c0-4.478 2.943-8.268 7-9.542v2.124A8.003 8.003 0 0 0 12 20a8.003 8.003 0 0 0 7.418-5h2.124c-1.274 4.057-5.064 7-9.542 7zm9.95-9H11V2.05c.329-.033.663-.05 1-.05 5.523 0 10 4.477 10 10 0 .337-.017.671-.05 1zM13 4.062V11h6.938A8.004 8.004 0 0 0 13 4.062z" />
        </g>
      </svg>{" "}
      Key Informations
    </h3>
    <div className="Key-info__columns">
      {keyData.map((val, index) => {
        return (
          <div className="Key-info__info" key={index}>
            <h4 className="Key-info__label">{keyDataLabel[parseInt(index)]}</h4>
            <h3>{val}</h3>
          </div>
        );
      })}
    </div>
  </div>
);

KeyInfo.propTypes = {
  keyData: PropTypes.array,
  keyDataLabel: PropTypes.array,
};

export default KeyInfo;
